import { Fragment } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { jwtDecode } from "jwt-decode";
import { decode } from "punycode";
function AdminAuth({ children }: any) {
    const navigate = useNavigate();        
    try {
        const authProvider = useAuth();
        if (authProvider.token === undefined || !authProvider.token) {
            authProvider.authorize();
            return <Fragment></Fragment>;
        }
        const decoded = jwtDecode(authProvider.token);
        if (!(decoded as any).scope.includes(`${process.env.REACT_APP_ADMIN_AUTH_SCOPE}`)) {
            authProvider.authorize();
            return <Fragment></Fragment>;
        }
        let currentDate = new Date();
        if (decoded === undefined || decoded?.exp === undefined || decoded?.exp * 1000 < currentDate.getTime()) {
            console.log("token expired");
            authProvider.tryRefreshToken();
            return <Fragment></Fragment>;
        }
    }
    catch {        
        navigate("/Unauthorized");
    }
    
    return (
        <Fragment>
            {children}
        </Fragment>
    );
}

export default AdminAuth;