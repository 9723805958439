import { useEffect, useState } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";
import DataGridDisplay, { CellType, TableConfiguration } from "./dataGridDisplay";
import DataGridFilters, { DataGridFiltersFieldType, DataGridFiltersProps } from "./dataGridFilters";
import { Table } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import axios from "axios";
import InfiniteScroll from 'react-infinite-scroll-component';
import AdminGalleryDetail from "./AdminGalleryDetail";

const { Cell } = Table;

function AdminGalleryList() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const authContext = useAuth();
    const navigate = useNavigate();
    const [apiData, setApiData] = useState<any>();
    const [infiniteData, setInfiniteData] = useState<Array<any>>();
    const [showGrid, setShowGrid] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(parseInt(window.sessionStorage.getItem("grid_gallery_page") || "1"));
    const [limit, setLimit] = useState(parseInt(window.sessionStorage.getItem("grid_gallery_limit") || "10"));
    const [filterApproved, setFilterApproved] = useState(window.sessionStorage.getItem("grid_gallery_filterApproved") || null);

    useEffect(() => {
        setSectionName("Gallery");
    }, [])

    const setPageSession = (page: number) => {
        window.sessionStorage.setItem("grid_gallery_page", page.toString());
        setPage(page);
    }
    const setLimitSession = (limit: number) => {
        window.sessionStorage.setItem("grid_gallery_limit", limit.toString());
        setLimit(limit);
    }
    const setFilterApprovedSession = (name: boolean | null) => {
        setFilterApproved(name?.toString() || null);
        window.sessionStorage.setItem("grid_gallery_filterApproved", name?.toString() || "");
    }

    const renderConfig: Array<TableConfiguration> = [
        {
            header: "",
            dataKey: "id",
            grow: 0,
            sortable: false,
            type: CellType.CUSTOM,
            fixed: true,
            width: 50,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        <Button variant="link"
                            onClick={() => {
                                navigate(`/admin/gallery/${rowData.id}`);
                            }}
                        >      <EditIcon></EditIcon>
                        </Button>

                    </Cell>
                );
            }
        },
        { header: "Gruppo", dataKey: "group", grow: 1, sortable: true, type: CellType.BUILTIN, fixed: false },
        {
            header: "Approvata", dataKey: "hasPendingItems", grow: 1, sortable: false, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.hasPendingItems ? 'NO' : 'SI'}
                    </Cell>
                );
            }
        }

    ]
    const fetchNextData = () => {
        showLoader();
        let config = {
            params: {
                approved: null,
                page: page + 1,
                pageSize: 5,
                sortColumn: "Id",
                sortDirection: "desc"
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/Gallery/requests", config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    if (infiniteData == null || infiniteData === undefined || infiniteData?.length <= 0) {
                        setInfiniteData(response.data.items)
                    } else {
                        setInfiniteData(infiniteData => [...infiniteData ?? [], ...response.data.items]);
                    }
                    setPage(response.data.page);
                    setTotal(response.data.totalCount);
                }

            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);

            });
    }
    const fetchData = (page: number, limit: number, sortCol?: string, sortType?: any) => {
        showLoader();
        let config = {
            params: {
                approved: filterApproved,
                page: page,
                pageSize: limit,
                sortColumn: sortCol,
                sortDirection: sortType
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/Gallery/requests", config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setApiData(response.data.items);
                    setPage(response.data.page);
                    setTotal(response.data.totalCount);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const filterConfig: DataGridFiltersProps = {
        fields: [
            {
                name: "Approvate",
                type: DataGridFiltersFieldType.CHOICE,
                fieldContext: [filterApproved, setFilterApproved],
                choiceOptions:
                    [
                        { label: "Tutto", value: "" },
                        { label: "SI", value: true },
                        { label: "NO", value: false }
                    ]
            }
        ],
        onSearch: () => fetchData(1, limit),
        onClear: () => {
            setFilterApprovedSession(null);
        },
        onNew: () => { },
        onExport: () => {
        },
        hideExport: true,
        hideNew: true
    }
    return (
        <Container fluid className="admin-main-content">
            <Row style={{ marginTop: "16px" }}>
                <Col>
                    <Form.Check
                        type="switch"
                        id={`showGrid-switch`}
                        key={`showGrid-switch`}
                        label="Griglia"
                        onChange={(event: any) => {
                            setShowGrid(event.target.checked);
                            setPageSession(1);
                            setInfiniteData([]);
                            if (event.target.checked) { fetchNextData(); }
                        }}
                        checked={showGrid}
                    />
                </Col>
            </Row>
            <>
                {(() => {
                    return showGrid ?
                        <>
                            <InfiniteScroll
                                next={fetchNextData}
                                dataLength={infiniteData?.length ?? 0}
                                hasMore={true}
                                loader={<h4>Caricamento...</h4>}
                                scrollableTarget="main-container"
                            >
                                {infiniteData?.map((i, index) => (
                                    <AdminGalleryDetail key={`infinitedetail-${index}`} idGallery={i.id} />
                                ))}
                            </InfiniteScroll>
                        </>
                        :
                        <>
                            <DataGridFilters {...filterConfig}></DataGridFilters>
                            <Divider></Divider>
                            <DataGridDisplay
                                showLoader={showLoader}
                                hideLoader={hideLoader}
                                fetchData={fetchData}
                                useDataContext={() => [apiData, setApiData]}
                                usePageContext={() => [page, setPageSession]}
                                useTotalContext={() => [total, setTotal]}
                                useLimitContext={() => [limit, setLimitSession]}
                                renderConfiguration={renderConfig}
                            ></DataGridDisplay>
                        </>
                })()}
            </>
        </Container>
    );
}

export default AdminGalleryList;