import axios from "axios";
import { blob } from "node:stream/consumers";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";
import { Loader, Placeholder } from 'rsuite';
import ProgressiveImage from "react-progressive-graceful-image";
import ReactPlayer from "react-player";

type GalleryRequest = {
    requestId?: number | null,
    group?: string | null,
    items?: GalleryRequestItem[] | null
}

type GalleryRequestItem = {
    id: number,
    name: string,
    sasUri: string,
    thumbSasUri: string,
    status: string
}

type BlobStatus = {
    name?: string | null,
    status?: string | null
}

function AdminGalleryDetail({ idGallery }: any | null) {
    const { galleryId } = useParams();
    const requestId = idGallery == null || idGallery === undefined ? galleryId : idGallery;
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const authContext = useAuth();
    //const [showDelete, setShowDelete] = useState(false);
    //const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [gallery, setGallery] = useState<GalleryRequest>({} as GalleryRequest);
    const [validated, setValidated] = useState(false);
    // const navigate = useNavigate();
    // let blobStatusTrack = Array<BlobStatus>();
    const [hasLoaded, setHasLoaded] = useState(false);

    const fetchGalleryById = (newUserId?: string) => {
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/Gallery/requests/${requestId}`, config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setGallery(response.data);
                    setHasLoaded(true);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const generateThumb = (imageId?: number) => {
        showLoader();
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/Gallery/requests/${requestId}/generate`, [imageId], config)
            .then(response => {
                hideLoader();
                //if (response.data) {
                //    setGallery(response.data);
                //}
                fetchGalleryById();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }
    const approveImage = (blobName: string, callback: any) => {
        showLoader();
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/Gallery/requests/${requestId}/approve`, [blobName], config)
            .then(response => {
                hideLoader();
                callback();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }
    const rejectImage = (blobName: string, callback: any) => {
        showLoader();
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/Gallery/requests/${requestId}/reject`, [blobName], config)
            .then(response => {
                hideLoader();
                callback();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const handleSubmit = (event: any) => {
        showLoader();
        const form = event.currentTarget;
        let formValid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
        if (formValid) {
            //saveGallery();
        }
        else {
            hideLoader();
        }
    };
    const handleApproveSwitchChange = (event: any) => {
        let itemName = event.target.getAttribute("data-item");
        if (gallery?.items !== null && gallery?.items !== undefined) {
            let propIndex = gallery.items.findIndex(x => x.name === itemName);
            let prevItems = [...gallery.items];
            prevItems[propIndex].status = event.target.checked ? "approved" : "pending";
            if (event.target.checked) {
                approveImage(itemName, () => {
                    setGallery({ ...gallery, items: prevItems })
                });
            }

            else {
                rejectImage(itemName, () => {
                    setGallery({ ...gallery, items: prevItems })
                });
            }

        }

    }
    useEffect(() => {
        showLoader();
        setSectionName("Dettaglio Gallery");
        fetchGalleryById();
    }, [])


    return (

        <Container fluid className="admin-main-content t-guest-data">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        Id: {gallery?.group}
                    </Col>
                </Row>
                {(() => {
                    return hasLoaded ?
                        <Row>
                            {(() => {
                                let images = [];
                                let re = /(?:\.([^.]+))?$/;
                                if (gallery?.items !== undefined && gallery?.items !== null) {
                                    for (let blob of gallery?.items) {
                                        let isVideo = false;
                                        let reResult = re?.exec(blob.name);
                                        if (reResult != null) {
                                            let ext = reResult[1];
                                            isVideo = ["mp4", "mov"].includes(ext);
                                        }
                                        images.push(<>
                                            <Col lg={2}><Card>
                                                <Card.Body>

                                                    <Row>{/*<img style={{ width: "100%" }} src={blob.thumbSasUri} alt={blob.name} />*/}
                                                        {!isVideo &&
                                                            <ProgressiveImage src={blob.thumbSasUri} placeholder="" onError={() => console.log("error")}>
                                                                {(src, loading) => loading ? <Loader content={blob.name} size="md" /> : <img style={{ width: "100%" }} src={src} alt={blob.name} />}
                                                            </ProgressiveImage>
                                                        }
                                                        {isVideo && 
                                                            <ReactPlayer url={blob.sasUri} controls/>
                                                        }
                                                    </Row>
                                                    <Row style={{ marginTop: "16px" }}>
                                                        <Col>

                                                            <Form.Check
                                                                type="switch"
                                                                id={`approve-switch${blob.id.toString()}`}
                                                                key={`approve-switch${blob.id.toString()}`}
                                                                label="Approva"
                                                                data-item={blob.name}
                                                                onChange={handleApproveSwitchChange}
                                                                checked={blob.status !== null && blob.status !== undefined && blob.status === "approved"}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                variant="link"
                                                                onClick={() => { generateThumb(blob.id) }}
                                                            >
                                                                Genera Thumb
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            </Col >
                                        </>)
                                    }
                                }
                                return images;
                            })()}
                        </Row>
                        :
                        <Row>
                            <Col>
                                <Placeholder.Paragraph rows={8} />
                                <Loader content="Caricamento..." size="lg" />
                            </Col>
                        </Row>
                })()}
                {/*<Row className="button">*/}
                {/*    <Col>*/}
                {/*        <Button type="submit" className="t-primary-button">Salva</Button>*/}
                {/*    </Col>*/}
                {/*</Row >*/}
            </Form>
        </Container >

    );
}

export default AdminGalleryDetail;